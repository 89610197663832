// @import 'topo';

// @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;400&display=swap');
@import url('https://fonts.cdnfonts.com/css/circular-std');
@import url("https://use.typekit.net/tkh2ebr.css");

$white: #fff;
// $white: #fff;
$black: #2c1309;
$borderWidth: 3px;
$paddingY: 0.35em;
$max-site-width: 100%;

$blue: #000;
$gold: #fdba4a;
$red: $blue;

$sigFont:"futura-pt", sans-serif;
// $sigFont: courier, monospace;
$sigFontCompressed:$sigFont;
$deco:  $sigFont;
$script:  $sigFont;

:root {
  // color-scheme: light dark;
}
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.promo.category {
  display: none;
}

  a.hidden-link {

    font-weight: 600;
    display: inline-block;
    padding: 0.2em 0.2em;
    margin: 0.4em 0;
    font-size: 1.5em;
    background: $blue;
    color: $gold !important;
    border-radius: 0.3em;
    text-decoration: none;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: fade-in; 
  }


.charm.site-content {
  // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  // background: $white;
  // @include topobackground;
  color: $black;
  a {
    // color: #ff9100;
    color: #000;
    text-decoration-style: double;
    font-weight: 600;
    text-decoration-thickness: 2px;
    text-decoration-skip-ink: none;
  }
}


.charm {
  font-family: $sigFont;
  p.flashy {
    font-family: $deco;
    font-size: 1.5em;
    font-weight: 600;
    color: $red;
    text-transform: uppercase;
    text-align: center;
    margin: 0.5em 0;
  
  }
  // color change animation for .flashy
  p.flashy {
    // change one time
    animation: colorchange 5s forwards;
    // stop animation after 5s
    animation-fill-mode: forwards;
    
  }
  @keyframes colorchange {
    0% {
      color: rgb(228, 194, 2);
    }
    50% {
      color: $red;
    }
    100% {
      color: gold;
    }
  }
  
  .menu-nav-wrapper {
    top: 0;
  }
  .category-link-item {
    padding: 1em 0 !important;
  }
  .cart-wrapper {
    position: relative;
    
    
  }
  .cart.minimized {
    padding: 2em 1em 3em 1em;

  }
  .home-page-blocks {
    display: flex;
    flex-wrap: wrap;
  }
  .text-block {
    font-weight: 200;
    font-size: 0.8em;
    text-align: center;
    align-items: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 6.5em;
    img.new-restaurant-name {
      display: block;
      max-width: 8em;
      width: 100%;
      padding: 1em 0.5em;
      margin: 0 auto;
    }
    strong {
      font-weight: 600;
    }
    &.tagline {
      font-size: 1em;
      
      width: 100%;
      
    }
    &.address, &.hours {
      width: 25%
    }
    &.ordering {
      width: 100%;
      a {
        line-height: 2em;
      }
    }
   
  }
  .image-block {
    width: 50%;
  }
  @media only screen and (max-width: 700px) {
    .text-block, .image-block {
      width: 100% !important;  
     }
  }

  .menu-story {
    padding-top: 0 !important;
  }
  .ordering-header {
    background-image: url(https://afag.imgix.net/charm/hero-1.png?w=1200&auto=format);
    text-align: center;
    background-size: cover;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    line-height: 1.5em;
    padding: 0em 0 0 0;
    .text {
      background: rgba(255,255,255,0.8);
      padding: 0.5em;
      border-radius: 0.5em;
    }
    .title {
      font-family: $deco;
      font-weight: 600;
      
    }
  }
  .product-wrapper {
    background: none !important;
    background-image: none !important;
  }
  .product-modifiers {
    display: block;
    // align-items: space-around;
 
    .modifier {
      min-height: 0em !important;
      border-radius: 0.5em;
      border: 1px solid rgba(200,200,200,0.5);
      line-height: 1.8em;
      width: 100%;
      text-align: left;
      margin: 0.1em;
      .label {
        padding: 0;
      }
      .text {
        flex-direction: row;
        justify-content: space-between;
      }
      &.picked {
        background: #3f74cb;
        color: white;
      }
    }
  }
  


  .landing-page-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
    .photos.grid, .menu-category-list-view {
      max-width: 1040px;
    }

    .menu-item {
      .photo-container {
        // width: 400px;
      }
    }
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    background: none !important;
    color: $black;
    border-radius: 0.1em !important;
    padding: 0;
    font-weight: 800;
    box-shadow: none;
    border: 2.5px solid $black !important;
    padding: 0.5em;
    // text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    // padding: 0em 0.3em !important;
    // font-size: 1em;
    // font-weight: 900 !important;
  }

  img.nav-bar-logo {
    display: block;
    position: relative;
    height: 150px;

    // margin-top: -0.4em;
    // bottom: -0.6em;
    padding: 0.2em 0.5em;
  }
  // .cart-wrapper {
  //   // position: relative;
  //   &:after {

  //     background:
	// 				linear-gradient(-45deg, transparent 16px, $gold 0),
	// 				linear-gradient(45deg, transparent 16px, $gold  0);
  //       background-repeat: repeat-x;
	// 	  background-position: left bottom;
  //     background-size: 22px 32px;
  //     // transition: all 0.3s;
  //     content: "";
  //     display: block;

	// 	width: 100%;
	// 	height: 13px;

 	//    position: relative;
	// 	// top:64px;
	// 	left:0px;
  //   }
  // }
    .site-top-notificaiton {
      background: black;
      color: white;
      text-align: center;
      padding: 0.5em;
      
      ;
    }
  .cart.minimized {
    font-family: $script;
    letter-spacing: 0.04em;
    font-size: 0.9em;
    // background: white;
    
    background: none;
    max-width: $max-site-width;
    margin: 0 auto;
    // padding-left: 0;
    // padding-bottom: 0.1em;
    border-bottom: 0px solid rgba(150,150,150,0.6);


  }
  // .hide {
  //   // position: absolute;
  //   width: 100%;
  // }
  .hero-banner {
    background-image: url(https://afag.imgix.net/beachwood-cafe/hero-food.jpg?w=1200);
    background-size: cover;
    min-height: 420px;
    // max-width: 720px;
    margin: 0 auto;
    // width: 500%;
    background-position: center 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 1px 1px 1px solid black;
    p {
      font-family: $sigFontCompressed;
      font-size: 0.9em;
    }
    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }

    .text {
      // height: 400px;
      position: relative;
      // bottom: -1em;

      font-family: $script;
      color: $blue;
      box-sizing: border-box;
      padding: 1em 1em;
      // border-radius: 0.2em;
      background: #d2fdf4;
      font-size: 1.5em;
      margin: 0;
      // margin-bottom: -4em;
      font-weight: 600;
      overflow: hidden;
      text-align: center;
      line-height: 0.9em;
      // box-shadow: -2px 0px 0px black;
      .small {
        font-size: 0.8em;
      }
      h1 {
        // text-transform: uppercase;
        font-family: $script;
        font-weight: 600;
        // font-family: $deco;
      }
      .info {

        font-family: $sigFontCompressed;
        font-size: 0.8em;
      }

      @media screen and (max-width: 550px) {
        font-size: 1.1em;
      }

    }
  }


  .location-info {

    padding: 1em 0.5em;
    text-align: center;
    h2 {
      font-family: $deco;
      font-size: 2em;
      margin-top: 0.5em;
    }
    p {
      margin: 0;
    }
  }




  .category-name .text {
    color: $blue;

    font-family: $deco;
    font-weight: 600;
    // text-transform: uppercase;
    font-size: 1.5em;
    // line-height: 0.7em;
    // padding: 0.3em 0.3em 0.2em 0.3em;
    border-radius: 3px;
    background: none;
    position: relative;


  }
  .menu-category-list-view {
    // text-transform: lowercase;
    .food-name {
      font-weight: 400;
      font-family: $sigFont;
      // font-size: 1.2em;
    }
    .price {
      font-family: $sigFont;
    }
    .content {
      // font-size: 1.3em;
      // width: 70%;
    }
  }

  // WINE products special display

  .top-banner {
    min-height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-shadow: 1px 1px 0 $red;
    .text {
      display: inline-block;
      background: black;
      padding: 0.4em 0.5em;
      border-radius: 0.2em;
      // text-transform: uppercase;
      h1 {
        font-weight: 900;
        font-family: $deco;
      }
    }
  }

  .pages-nav {
    letter-spacing: 0.04em;
    font-size: 1em;
    max-width: $max-site-width;
    margin: 0 auto;
    font-family: $script;
    font-weight: 800;
    padding-left: 0.4em;
    display: flex;
    flex-wrap: warp;
    align-items: center;
    .react-toggle {
      margin-right: 0.5em;
    }
    ul {
      padding: 0.2em 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: stretch;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  a.page-link {
    text-decoration: none;
    color: $blue;
    display: block;
    font-weight: 600;
    line-height: 0.7em;
    padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
    border-bottom: $borderWidth solid #f5f5f5;
    &:hover, &.active {
      color: black;
      border-bottom: $borderWidth solid $red;

    }
  }
  a.order-online-button {
    // margin: 2em 0;
    font-size: 0.8em;
    display: inline-block;
    color: $white;
    text-decoration: none;
    padding: 0.3em 0.5em;
    margin: 0.5em 0;
    background: $red;
    font-family: $sigFontCompressed;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 15px;
    box-shadow: 1px 1px #ffbc50, 3px 3px #ffbc50, 5px 5px #ffbc50, 7px 7px #ffbc50;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
  }
  a.order-from-old {
    font-size: 0.4em;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.3em 0.5em;
    background: black;
    font-family: $sigFontCompressed;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 15px;
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $red;
      color: white;
    }
  }
    .menu-item {
      header {
        // font-family: $sigFont;
        // font-size: 1.2em;
      }
    }

    .footer {
      .content {
        display: none;
      }
      font-size: 0.8em;
      margin-top: 100px;
      font-family: $sigFontCompressed;
      // text-align: right;
      // padding-right: 220px;
      color: $blue;
      background: url(https://afag.imgix.net/charm/bottom-1.png?w=1200&auto=format) no-repeat;
      background-position: center;
      // background-size: 350px;
      min-height: 500px;
      padding-bottom: 0;
      // background: none;
      a.phone-link {
        color: inherit;
      }
    }

  }

  .page-title {

    font-family: $deco;
    font-weight: 600;
    // font-size: 2em;
    padding: 1em 0;
    text-align: center;
  }

  .photos {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 800px;
    margin: 0 auto;
    .photo-wrapper {

      width: 45%;
      img {
        border: 4px solid black;
        width: 100%;
      }
    }
  }


  .menu-category-list-view {
    .mag-glass-svg {
      display: none;

    }
  }

  .page-header {
    max-width: 650px;
    margin: 0 auto;
    p {
      line-height: 1.3em;
    }
  }
  .page-title {
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
    font-family: $deco;
    text-transform: uppercase;
    margin-top: 1em;
  }
  .contact-page {
    .top-banner {
      margin: 1em 0;
      width: 100%;
    }
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 2em;
    padding: 0 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5em;
    strong {
      font-weight: 600;
    }
    h2 {
      font-weight: 900;
      font-family: $deco;
      text-transform: uppercase;
      margin: 1em 0;
    }
    ul {
      list-style: disc;
      padding-left: 1em;
    }
    li, p {
      margin: 0.5em 0;
    }
  }
